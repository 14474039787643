import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useForm } from "react-hook-form";
import { useAtomValue } from "jotai";
import { atomWithHash } from "jotai-location";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { AutoTable, Button, FilterDialog, FormSelect, Grid, Icon, Search, TableFooterPagination, useFilter, usePagination } from "@stationkim/front-ui";
import routesPaths from "presentation/routes/routesPaths";
import history from "@/services/history";
import { FORM_MODE, NOTIFICATION_TYPE, OPERATOR_CARD_STATUS } from "main/utils/constants";
import { pushNotification } from "@/store/modules/notification/actions";
import { faFilter } from "@fortawesome/free-solid-svg-icons";
import { getTableConfiguration } from "./utils/getTableConfiguration";
import { operatorIdAtom } from "@/main/store";
import { useOperatorCards } from "./hooks/useOperatorCards";
import { benefitTypes, operatorCardFeeStatus } from "@/main/utils/options";
import api from "@/infra/api";
import { StatusToggleDialog } from "../../components/statusToggleDialog";

const initialFilter = { sort: 'code', desc: false }
const filterAtom = atomWithHash<object>('operatorsFilter', initialFilter)
const paginationAtom = atomWithHash<object>('operatorsPagination', null)
const filterDefaultValues = { status: '', benefitType: '' }

export const OperatorCards = ({ operator }) => {
  const operatorId = useAtomValue(operatorIdAtom)

  const dispatch = useDispatch();
  const { filter, resetFilter, updateFilter } = useFilter({ filterAtom, initialValue: initialFilter })
  const { pagination, goToPage, setPageSize } = usePagination({ paginationAtom: paginationAtom })
  const { operatorCards, isFetching: isLoadingOperatorCards } = useOperatorCards({ operatorId, filter, pagination })
  const [cardToChange, setCardToChange] = useState(null);
  const open = Boolean(cardToChange)
  const isCardActive = cardToChange?.status === OPERATOR_CARD_STATUS.ACTIVE;
  const queryClient = useQueryClient()
  const [isFilterOpen, setIsFilterOpen] = useState(false);

  const operatorCardStatus = useMutation({
    mutationFn: ({
      operatorId,
      cardId,
      status
    }: {
      operatorId: string
      cardId: string
      status: boolean
    }) => api.patch(`/operators/${operatorId}/card/${cardId}/activate/${status}`),
  })

  const filterForm = useForm({ defaultValues: filterDefaultValues });

  const generateLink = (cardId, formMode) => {
    return `${routesPaths.operators.visualize_card}#operatorId="${operatorId}"&operatorCardId="${cardId}"&operatorCardFormMode="${formMode}"`

  };

  const sendToCardVisualization = (operatorCardId, formMode) => {
    history.push(generateLink(operatorCardId, formMode))
  }

  const onEdit = ({ card }) => {
    if (card.status === OPERATOR_CARD_STATUS.ACTIVE)
      sendToCardVisualization(card.id, FORM_MODE.EDIT)
    else dispatch(pushNotification({
      type: NOTIFICATION_TYPE.WARNING,
      content: "Para editar um cartão é necessário que ele esteja ativo."
    }));
  }

  const onStatusChange = ({ card }) => {
    setCardToChange(card)
  }

  useEffect(() => {
    if (filter)
      for (const key in filter) {
        filterForm.setValue(key as any, filter[key] ?? '')
      }
  }, [filter])

  return (
    <>
      <Grid container spacing='16px' padding={'0px 32px 0'}>
        <Grid item xs={12} md={6} lg={4} xl={3}>
          <Search
            onSearch={(value) =>
              updateFilter({
                filterValue: value,
                filterProps: 'code,name',
              })
            }
            placeholder="Buscar por número ou nome"
          />
        </Grid>
        <Grid item xs={12} sm={6} md='auto'>
          <Button
            onClick={() => setIsFilterOpen(true)}
            fullWidth
            variant="outlined"
            startIcon={<Icon icon={faFilter} />}
          >
            Filtrar
          </Button>
        </Grid>
        <Grid item xs={12} sm={6} md='auto' sx={{ marginLeft: 'auto' }}>
          <Button
            disabled={!operator.tradeName}
            fullWidth
            variant="contained"
            onClick={() => {
              history.push({
                pathname: routesPaths.operators.register_card,
                state: {
                  operator
                }
              })
            }}
          >
            Novo cartão
          </Button>
        </Grid>
        <Grid item xs={12}>
          <AutoTable
            link={({ row }) => { return generateLink(row.id, FORM_MODE.VIEW) }}
            rows={operatorCards?.data}
            columns={getTableConfiguration({ onEdit, onStatusChange })}
            isLoading={isLoadingOperatorCards}
          />
        </Grid>
        <Grid item xs={12}>
          <TableFooterPagination
            totalItems={operatorCards.totalItens}
            rowsPerPage={operatorCards.pageSize}
            totalPages={operatorCards.totalPages}
            page={operatorCards.pageNumber}
            onRowsPerPageChange={(e: any) => setPageSize(e.target.value)}
            onPageChange={(e, value) => goToPage(Number(value))}
          />
        </Grid>
      </Grid>
      <FilterDialog
        open={isFilterOpen}
        onClose={() => setIsFilterOpen(false)}
        onClean={() => {
          resetFilter()
          filterForm.reset(filterDefaultValues)
          setIsFilterOpen(false)
        }}
        onFilter={(data) => {
          updateFilter(data)
          setIsFilterOpen(false)
        }}
        form={filterForm}
      >
        <FormSelect
          label='Status'
          name="status"
          placeholder="Selecione"
          options={operatorCardFeeStatus}
        />
        <FormSelect
          label='Benefício'
          name="benefitType"
          placeholder="Selecione  "
          options={benefitTypes}
        />
      </FilterDialog>
      <StatusToggleDialog
        open={open}
        onClose={() => setCardToChange(null)}
        isActive={isCardActive}
        onConfirm={() =>
          operatorCardStatus.mutate({
            cardId: cardToChange.id,
            operatorId,
            status: isCardActive ? false : true
          }, {
            onSuccess: () => {
              setCardToChange(null)
              queryClient.invalidateQueries({ queryKey: ['operatorCards'] })
            }
          })
        }
      />
    </>
  );
};
