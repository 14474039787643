import { OPERATOR_CARD_FEE_STATUS } from 'main/utils/constants';
import { Tooltip } from '@mui/material';
import styled from 'styled-components';

export const OperatorInformation = ({ children }) => {
  return (
    <Tooltip title={children}>
      <p style={{
        marginTop: "8px",
        fontSize: "0.875rem",
        overflow: "hidden",
        textOverflow: "ellipsis",
        whiteSpace: "nowrap"
      }}>{children}</p>
    </Tooltip>
  );
};
export const LoadingWrapper = styled.div`
  height: 350px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const FileLink = styled.a`
  display: inline-flex;
  gap: 8px;
  color: var(--color-primary-01);
  font-weight: var(--font-weight-regular);
  margin-top: 8px;
  font-size: 0.875rem;
`;

export const EntriesCounter = styled.div`
  display: flex;
  align-items: center;
  button{
    margin-left: 24px;
  }
`;

export const AddEntrieTextButton = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  border: none;
  background-color: transparent;
  font-weight: var(--font-weight-black);
  color: ${({ isDisabled }) => isDisabled ? "#672ED75C" : "var(--color-primary-01)"};
  padding-left: 26px;
  position: relative;
  cursor: pointer;
  font-size: 1rem;
  &::before{
    display: flex;
    align-items: center;
    justify-content: center;
    content: "+";
    border-radius: 50%;
    background-color: ${({ isDisabled }) => isDisabled ? "#672ED75C" : "var(--color-primary-01)"};
    color: white;
    position: absolute;
    font-size: 12px;
    left: 0;
    height: 16px;
    width: 16px;
  }
`;

export const CategorieTitleWrapper = styled.div`
  display: flex;

  button{
    margin-left: 24px;
  }
`;

export const TextCenter = styled.div`
  text-align: center;
  margin: auto;
`;

export const StatusDot = styled.div`
  border-radius: 50%;
  margin-right: 8px;
  height: 8px;
  width: 8px;
  background-color: ${({ status }) => status === OPERATOR_CARD_FEE_STATUS.ACTIVE ? "var(--color-status-01)" : "var(--color-status-02)"};
`;

export const StatusWrapper = styled.div`
  display: flex;
  align-items: center;
`;

export const Form = styled.form`
  label {
    display: block;
    margin-bottom: 8px;
  }
`;

export const ButtonWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-top: 32px;

  button {
    max-width: 166px;
    margin-left: 24px;
  }
`;
export const StepsWrapper = styled.div`
  max-width: 800px;
  margin: auto;
  margin-bottom: 48px;
  margin-top: 32px;
`;

export const ViewParagraph = styled.p`
  color: #4c27a0;
  padding-top: 10px;
  font-weight: bold;
`;